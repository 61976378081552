.order-card {
    display: flex;
    align-items: center;
   
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .product-info {
    display: flex;
    align-items: center;
  }
  
  .product-image {
    width: 170px;
    height: 150px;
    /* object-fit: cover; */
    margin-right: 10px;
    margin-left: 15px;
    border-radius: 10px;
  }
  
  .product-details {
    flex: 1;
    text-align: left;
   /* width: 640px;   */
    /* margin-left: 30px; */
  }
  
  .quantity-controls {
    display: flex;
    align-items: center;
    
  }

  .quantity_details{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    /* margin-left: 75px; */
  }
  
  .quantity-button {
    background-color: #D941FF;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 50%;
    
  }
  
  .quantity {
    margin: 0 10px;
  }

  .product_save{
     background-color: #ffffff;
     /* margin-left: 50px; */
     color: black;
     border: #ffffff;
  }

  .product_remove{
    background-color: #ffffff;
    /* margin-left: 50px; */
    color: black;
    border: #ffffff;
    margin-top: -122px;

 }

 .del_days{
    margin-left: 50px;
    margin-top: -120px;
 }

 .place_del{
    color: white;
    background-color: #B89D4D;
    margin-left: 80%;
    padding: 10px;
    border: #B89D4D;
    border-radius: 10px;

 }

 .emptycart{
  font-family: "Segoe UI-Semibold", Helvetica;
  font-size: 30px;
  font-weight: 400;
   
  /* color: white; */
 }


 @media screen and (min-width:320px) and (max-width:480px) {

  .product-image {
    height: 150px;
    margin-left: -400px;
    margin-right: 10px;
    width: 170px;
  }
  
  .order-card, .product-info {
    align-items: center;
    display: block;
    height: 325px;
  }
  
  hr {
    border: 0;
    border-top: 1px solid;
    border-top: var(--bs-border-width) solid;
    color: inherit;
    margin: 1rem 0;
    opacity: .25;
    width: 39%;
  }
  
  .product-name{
    font-size: 9px;
      text-align: justify;
  }
  
  .quantity_details {
    display: block;
    justify-content: space-between;
    margin-left: 1px;
    margin-top: 10px;
  }
  
  .quantity-controls {
    align-items: center;
    display: block;
  }
  
  .product_remove, .product_save {
    background-color: #fff;
    border: #fff;
    color: #000;
    margin-left: -2px;
    margin-top: 10px;
  }
  
  .price_amount1, .price_amount5 {
    background-color: #fff;
    box-sizing: border-box;
    flex: 3 1;
    height: 9%;
    margin-left: 0px;
    margin-top: 20px;
    position: relative;
    width: 176%;
  }
  .place_del {
    background-color: #B89D4D;
    border: #B89D4D;
    color: #fff;
    margin-left: -8%;
    padding: 10px;
    margin-top: 20%;
}

.product-image {
  height: 150px;
  /* margin-left: -99px; */
  margin-right: 10px;
  width: 170px;
}

.emptycart {
  font-family: Segoe UI-Semibold, Helvetica;
  font-size: 13px;
  font-weight: 400;
  margin-left: 2%;
  margin-top: 15%;
}

.product-details {
  flex: 1 1;
  text-align: left;
  width: 46%;
}
.product-image {
  height: 100px;
  margin-right: 10px;
  width: 100px;
}

 }

 @media screen and (min-width:576px) and (max-width:760px) {


  .product-image {
    height: 150px;
    margin-left: -400px;
    margin-right: 10px;
    width: 170px;
  }
  
  .order-card, .product-info {
    align-items: center;
    display: block;
  }
  
  hr {
    border: 0;
    border-top: 1px solid;
    border-top: var(--bs-border-width) solid;
    color: inherit;
    margin: 1rem 0;
    opacity: .25;
    width: 73%;
  }
  
  .product-name{
    font-size: 9px;
      text-align: justify;
  }
  
  .quantity_details {
    display: block;
    justify-content: space-between;
    margin-left: 1px;
    margin-top: 10px;
  }
  
  .quantity-controls {
    align-items: center;
    display: block;
  }
  
  .product_remove, .product_save {
    background-color: #fff;
    border: #fff;
    color: #000;
    margin-left: -2px;
    margin-top: 10px;
  }
  
  .price_amount1, .price_amount5 {
    /* background-color: #f6f6f6; */
    box-sizing: border-box;
    flex: 3 1;
    height: 26%;
    margin-left: 38px;
    margin-top: 20px;
    width: 23%;
    position: relative;
    background-color: #fff;
  }
 }

 @media screen and (min-width:768px) and (max-width:992px) {

 .quantity_details {
  display: flex;
  justify-content: left;
  margin-left: 9px;
  margin-top: 10px;
}

.product_remove, .product_save {
  background-color: #fff;
  border: #fff;
  color: #000;
  margin-left: 38px;
}

.product-name{
  width: 50%;
}
.final_img {
  height: 425px;
  width: 966px;
}

.slide_off {
  height: 360px;
  left: 0;
  margin-right: 465px;
  position: relative;
  top: 0;
  width: 575px;
}

.slide_off1 {
  height: 365px;
  left: 59%;
  position: absolute;
  width: 384px;
  margin-top: -362px;
}

.items1 {
  background-color: #f1f1f1;
  display: flex;
  height: 50px;
  padding: 12px;
  width: 89%;
}

 }

 @media screen and (min-width:1024px) and (max-width:1190px) {

 .quantity_details {
  display: flex;
  /* justify-content: space-between; */
  margin-left: -7px;
  margin-top: 10px;
  justify-content: left;
} 

.product-name{
  width: 50%;
}

.final_img {
  height: 425px;
  width: 991px;
}

.slide_off {
  height: 360px;
  left: 0;
  margin-right: 465px;
  position: relative;
  top: 0;
  width: 598px;
}
.slide_off1 {
  height: 365px;
  left: 60%;
  position: absolute;
  width: 336px;
  margin-top: -363px;
}

 }

 @media screen and (min-width:1200px) and (max-width:1250px) {

  .quantity_details {
   display: flex;
   /* justify-content: space-between; */
   margin-left: -7px;
   margin-top: 10px;
   justify-content: left;
 } 
 
 .product-name{
   width: 50%;
 }
 
 .final_img {
  height: 425px;
  width: 880px;
}
 
 .slide_off {
   height: 360px;
   left: 0;
   margin-right: 465px;
   position: relative;
   top: 0;
   width: 598px;
 }
 .slide_off1 {
   height: 365px;
   left: 60%;
   position: absolute;
   width: 336px;
   margin-top: 0px;
 }

 .sell_best_product {
  background-color: #f4f5f5;
  height: auto;
  position: relative;
  width: 89%;
}

.email_check {
  display: flex;
  width: 74%;
}

.header {
  padding: 5px;
  position: relative;
  width: 81%;
}

.store_loc {
  left: 74%;
}

.trackorder {
  cursor: pointer;
  left: 84%;
}
.country_name, .language_name {
  border: none;
  color: #7b7b7b;
  font-family: Segoe UI-Semibold, Helvetica;
  font-size: 15px;
  font-weight: 400;
  margin-top: 9px;
}

.banner-slideshow {
  height: 500px;
  overflow: hidden;
  position: relative;
  width: 89%;
}

.menu {
  height: 88px;
  margin-top: 30px;
}

.quantity_details {
  display: flex;
  justify-content: left;
  margin-left: 75px;
  margin-top: 10px;
}
  }
  

  @media screen and (min-width:1251px) and (max-width:1300px) {

    .quantity_details {
     display: flex;
     /* justify-content: space-between; */
     margin-left: -7px;
     margin-top: 10px;
     justify-content: left;
   } 
   
   .product-name{
     width: 50%;
   }
   
   .final_img {
    height: 425px;
    width: 880px;
  }
   
   .slide_off {
     height: 360px;
     left: 0;
     margin-right: 465px;
     position: relative;
     top: 0;
     width: 598px;
   }
   .slide_off1 {
     height: 365px;
     left: 60%;
     position: absolute;
     width: 336px;
     margin-top: 0px;
   }
  
   .sell_best_product {
    background-color: #f4f5f5;
    height: auto;
    position: relative;
    width: 100%;
}
  
  .email_check {
    display: flex;
    width: 74%;
  }
  
  .header { 
    padding: 5px;
    position: relative;
    width: 99%;
}
  
  .store_loc {
    left: 74%;
  }
  
  .trackorder {
    cursor: pointer;
    /* left: 87%; */
  }
  .country_name, .language_name {
    border: none;
    color: #7b7b7b;
    font-family: Segoe UI-Semibold, Helvetica;
    font-size: 15px;
    font-weight: 400;
    margin-top: 9px;
  }
  
  .sell_best_product {
    background-color: #f4f5f5;
    height: auto;
    position: relative;
    width: 99%;
}
  
  .menu {
    height: 88px;
    margin-top: 30px;
  }
  
  .quantity_details {
    display: flex;
    justify-content: left;
    margin-left: 75px;
    margin-top: 10px;
  }
    }

    /* Container to wrap the store cards */
.store-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns */
  gap: 20px; /* Space between cards */
  padding: 20px;
}

.order-card {
  border: 1px solid #ccc; /* Add a border */
  border-radius: 10px; /* Rounded corners */
  padding: 15px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for better look */
  transition: transform 0.2s;
}

.order-card:hover {
  transform: scale(1.02); /* Slight zoom effect on hover */
}

.product-info {
  display: flex;
  /* flex-direction: column; */
  /* align-items: center; */
  text-align: center;
}

.product-image {
  width: 100%;
  height: auto;
  max-width: 150px; /* Set a max width for the image */
  border-radius: 8px;
  margin-bottom: 10px;
}

.product-details {
  margin-top: 10px;
}

.product-name {
  font-size: 1.1em;
  font-weight: bold;
}

.del_days {
  margin-top: 10px;
  color: #777;
}

.save_for {
  font-size: 1.5em;
  font-weight: bold;
  margin: 20px 0;
}

.emptycart {
  font-size: 1.2em;
  color: #999;
  text-align: center;
}

/* Responsive layout for mobile devices */
@media (max-width: 768px) {
  .store-container {
    grid-template-columns: 1fr; /* One column for smaller screens */
  }
}
