/* Tabs.css */

.tabs-container {
  font-family: Arial, sans-serif;
}

.tabs {
  display: flex;
  /* justify-content: space-between; */
  /* border-bottom: 1px solid #ccc; */
}

.tab {
  padding: 10px 15px;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  transition: border-bottom-color 0.3s ease;
}

.tab.active {
  border-bottom-color: #B89D4D;
}

.tab-content1 {
  margin-top: 10px;
}

/* .detail_tab{
  text-align: left;
  margin-left: 10%;
}

.detail_tab1{
  text-align: left;
  margin-left: 20%;
display: block;
} */

.detail_tab {
  display: table;
    width: 80%;
    text-align: left;
    margin-left: 15%;
}

.detail_tab p {
  display: table-row;
}

.detail_tab b {
  display: table-cell;
  padding: 5px;
  width: 30%;
}

.detail_tab span {
  display: table-cell;
  padding: 5px;
}

.more_info{
  text-align: justify;
    display: inline-block;
    width: 70%; 
}
