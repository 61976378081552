/* Container for the entire food list */
.save_for {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.order-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 20px;
  /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);  */
  /* width: 316%; */
  /* margin-left:18px; */
}
.order-card1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);  */
  margin-left:31px;
}

.product-info {
  display: flex;
  align-items: center;
  width: 100%;
}

.product-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 10px;
  margin-right: 20px;
}

.product-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.product-name {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.product-price {
  font-size: 16px;
  color: #777;
  margin-top: 5px;
}

.total-amount {
  margin-top: 10px;
  font-weight: bold;
  color: #333;
}

.quantity_details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.quantity-controls {
  display: flex;
  align-items: center;
  background-color: #d2a757;
  border-radius: 10px;
}

.quantity-button {
  background-color: #ccc;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 13px;
  margin: 0 10px;
  cursor: pointer;
}

.quantity {
  font-size: 16px;
  padding: 0 10px;
}

.add-to-cart-button {
  background-color: #d2a757; /* Button color */
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  width:100px;
  margin-left:101.5%;
  margin-top:40px;
}


.add-to-cart-button1 {
  background-color: #d2a757; /* Button color */
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}
.add-to-cart-button:hover {
  background-color: #a07840; /* Darker shade on hover */
}

.del_days {
  margin-top: 10px;
}

.total-cart-price {
  font-size: 18px;
  font-weight: bold;
  margin: 20px 0;
  text-align: right;
}

/* Section for the final total and Add All to Cart button */
.total-section {
  text-align: right;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  margin-left:20px
}

/* Styling for the entire layout */
.emptycart {
  text-align: center;
  font-size: 20px;
  color: #777;
}

/* General layout styling */
@media (max-width: 768px) {
  .order-card {
    flex-direction: column;
    text-align: center;
  }

  .product-image {
    margin-bottom: 15px;
  }
}
