.autocomplete {
    position: relative;
    display: inline-block;
  }
  
  
  
  .suggestions {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #fff;
    /* border: 1px solid #ccc; */
    border-top: none;
    /* border-radius: 0 0 4px 4px; */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    list-style-type: none;
    padding: 0;
    margin: 0;
    z-index: 9999;
    width: 284%;
    text-align: justify;
  }
  
  .suggestions li {
    padding: 8px;
    cursor: pointer;
  }
  
  .suggestions li:hover {
    background-color: #f4f4f4;
  }
  .com_btn1{
    margin-left: 158px;
    position: relative;
    border: 1px solid #ccc; 
    height: 42px;
    width: 80px;
    color:#fff;
    background-color: #B89D4D;
  }

  @media screen and (min-width:320px) and (max-width:481px) {

  .suggestions {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: none;
    /* border-radius: 0 0 4px 4px; */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    list-style-type: none;
    padding: 0;
    margin: 0;
    z-index: 9999;
    width: 993%;
    text-align: justify;
  }

}